import { StarIcon } from '@heroicons/react/solid'

export default function Stats() {
  return (
    <div className='bg-gray-900 p-12 sm:p-16 '>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='max-w-4xl mx-auto text-center'>
          <h2 className='text-3xl font-extrabold text-gray-300 sm:text-4xl'>
            Trusted by lifters from all over the world
          </h2>
          {/* <p className='mt-3 text-xl text-gray-100 sm:mt-4'>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Repellendus repellat laudantium.
          </p> */}
        </div>
      </div>
      {/* <div className='mt-10 pb-12 bg-gray-900 sm:pb-16'>
        <div className='relative'>
          <div className='absolute inset-0 h-1/2 bg-gray-900' />
          <div className='relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='max-w-4xl mx-auto'>
              <dl className='rounded-lg bg-gray-800 shadow-lg sm:grid sm:grid-cols-3'>
                <div className='flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r justify-center'>
                  <dt className='order-2 mt-2 text-lg leading-6 font-medium text-gray-400'>
                    Apple & Google Play Rating
                  </dt>
                  <dd className='order-1 text-5xl font-extrabold text-primary-600'>
                    4.9
                    <div className='flex flex-1 flex-row justify-center '>
                      <StarIcon className='h-6 w-5' />{' '}
                      <StarIcon className='h-6 w-5' />
                      <StarIcon className='h-6 w-5' />
                      <StarIcon className='h-6 w-5' />
                      <StarIcon className='h-6 w-5' />
                    </div>
                  </dd>
                </div>
                <div className='flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r content-center justify-center'>
                  <dt className='order-2 mt-2 text-lg leading-6 font-medium text-gray-400'>
                    Lifters
                  </dt>
                  <dd className='order-1 text-5xl font-extrabold text-primary-600'>
                    10,000+
                  </dd>
                </div>
                <div className='flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l justify-center'>
                  <dt className='order-2 mt-2 text-lg leading-6 font-medium text-gray-400'>
                    Quadrillion Program Variations
                  </dt>
                  <dd className='order-1 text-5xl font-extrabold text-primary-600'>
                    15+
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}
