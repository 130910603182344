export default function HowItWorks() {
  return (
    <div className='bg-gray-800'>
      <div className='max-w-5xl mx-auto pt-10 pb-24 px-4 sm:pb-16 sm:px-6 lg:px-8'>
        <div className='text-center'>
          <p className='mt-1 text-4xl font-extrabold text-gray-100 sm:text-5xl sm:tracking-tight lg:text-6xl'>
            How It Works
          </p>
          <p className='max-w-4xl mt-5 mx-auto text-xl text-gray-400'>
            Juggernaut Training Systems is The Leader in Strength, having helped
            thousands of athletes from beginners to World Champions maximize
            their results and reach their goals. We deliver principle-based
            coaching through cutting-edge technology to help you get the best
            results of your life.
          </p>
        </div>
      </div>
    </div>
  )
}
