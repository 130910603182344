const faqs = [
  {
    id: 1,
    question: 'Is JuggernautBJJ just for advanced athletes?',
    answer:
      'JuggernautBJJ is suitable for anyone looking to improve their strength & conditioning for Jiu Jitsu. Because JuggernautBJJ adapts every program based on your individual characteristics and feedback, it will help all levels of athletes.',
  },
  {
    id: 2,
    question: 'What equipment do I need?',
    answer:
      'The majority of the program can be done with just a barbell, weights and rack. Beyond that, bands and a few pairs of Dumbbells or Kettlebells and a medicine ball would give you everything you’ll ever need to maximize performance.',
  },
  {
    id: 3,
    question: 'How many days per week is the training?',
    answer:
      'JuggernautBJJ offers 2-6 day/week programs. You choose how many days/week you want to train.',
  },
  {
    id: 4,
    question: 'How is this different from the JuggernautAI App?',
    answer:
      'This IS NOT A POWERLIFTING PROGRAM. JuggernautBJJ is designed to improve your performance in Jiu Jitsu, a goal that goes well beyond just increasing your Squat, Bench and Deadlift. While improving strength is part of improving BJJ performance, it isn’t just about the Big 3.',
  },
  // More questions...
]

export default function HomeFAQ() {
  return (
    <div className='bg-gray-800'>
      <div className='max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8'>
        <div className='lg:max-w-2xl'>
          <h2 className='text-3xl font-extrabold tracking-tight text-gray-100 sm:text-4xl'>
            Frequently asked questions
          </h2>
          {/* <p className="mt-4 text-white">
              Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec.
              Urna, sed a lectus elementum blandit et.
            </p> */}
        </div>
        <div className='mt-10'>
          <dl className='space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10'>
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className='font-semibold text-gray-100'>{faq.question}</dt>
                <dd className='mt-3 text-gray-400'>{faq.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
