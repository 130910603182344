import {
  InboxIcon,
  SparklesIcon,
  FingerPrintIcon,
  DocumentReportIcon,
  CheckIcon,
  AdjustmentsIcon,
} from '@heroicons/react/outline'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

const adjustments = [
  'Day to Day',
  'Week to Week',
  'Training Block to Training Block',
]
export default function Features() {
  const router = useRouter()
  return (
    <div className='relative bg-gray-800 pt-16 overflow-hidden'>
      <div className='relative'>
        <div className='lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24'>
          <div className='px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0'>
            <div>
              <div>
                <span className='h-12 w-12 rounded-md flex items-center justify-center bg-primary-600'>
                  <FingerPrintIcon
                    className='h-6 w-6 text-white'
                    aria-hidden='true'
                  />
                </span>
              </div>
              <div className='mt-6'>
                <h2 className='text-3xl font-extrabold tracking-tight text-gray-100'>
                  Tell our system about yourself so it can make the right
                  program for you{' '}
                </h2>
                <p className='mt-4 text-lg text-gray-400'>
                  Your program is created specifically for you. Your gender,
                  age, size, lifting experience and jiu jitsu training will all
                  factor into the workload of your training. We know that 22
                  year olds and Masters shouldn’t be training the same way and
                  JuggernautBJJ makes those changes for you.
                </p>
                <div className='mt-6'>
                  <Link
                    href={{
                      pathname: '/register',
                      query: router.query.via ? { via: router.query.via } : {},
                    }}>
                    <a className='inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700'>
                      Get started
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className='mt-8 border-t border-gray-200 pt-6'>
              <blockquote>
                <div>
                  <p className='text-base text-gray-400'>
                    &ldquo;As a Masters 3 competitor, working and coaching
                    full-time and with a baby at home, JuggernautBJJ has adapted
                    my training for me to be able to push myself and recover
                    properly.&rdquo;
                  </p>
                </div>
                <footer className='mt-3'>
                  <div className='flex items-center space-x-3'>
                    <div className='text-base font-medium text-white'>
                      Aubrey K
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className='mt-12 sm:mt-16 lg:mt-0'>
            <div className='pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full'>
              <Image
                src='/slide1.png'
                alt='Juggernaut BJJ'
                width={1600}
                height={1829}
                layout='responsive'
                objectFit='contain'
              />
              {/* <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="https://tailwindui.com/img/component-images/inbox-app-screenshot-1.jpg"
                    alt="Inbox user interface"
                  /> */}
            </div>
          </div>
        </div>
      </div>
      <div className='mt-24'>
        <div className='lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24'>
          <div className='px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2'>
            <div>
              <div>
                <span className='h-12 w-12 rounded-md flex items-center justify-center bg-primary-600'>
                  <DocumentReportIcon
                    className='h-6 w-6 text-white'
                    aria-hidden='true'
                  />
                </span>
              </div>
              <div className='mt-6'>
                <h2 className='text-3xl font-extrabold tracking-tight text-gray-100'>
                  Individualized programming made specifically for your goals{' '}
                </h2>
                <p className='mt-4 text-lg text-gray-400'>
                  JuggernautBJJ uses your feedback to create a program tailored
                  for you. Choose the primary goals of your training: Strength,
                  Speed/Power, Endurance, Tournament Prep or our Lifestyle
                  Program and our system will guide you on your way to progress,
                  adapting the program along the way based on your feedback.
                </p>
                <div className='mt-6'>
                  <Link
                    href={{
                      pathname: '/register',
                      query: router.query.via ? { via: router.query.via } : {},
                    }}>
                    <a className='inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700'>
                      Get started
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className='mt-8 border-t border-gray-200 pt-6'>
              <blockquote>
                <div>
                  <p className='text-base text-gray-400'>
                    &ldquo;These programs are full of high quality training and
                    tailored to exactly what I need to meet my goals.&rdquo;
                  </p>
                </div>
                <footer className='mt-3'>
                  <div className='flex items-center space-x-3'>
                    <div className='text-base font-medium text-white'>
                      Chloe D
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className='mt-12 sm:mt-16 lg:mt-0 lg:col-start-1'>
            <div className='pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full'>
              <Image
                src='/slide2.png'
                alt='Juggernaut AI'
                width={1600}
                height={1829}
                layout='responsive'
                objectFit='contain'
              />
              {/* <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="https://tailwindui.com/img/component-images/inbox-app-screenshot-2.jpg"
                    alt="Customer profile user interface"
                  /> */}
            </div>
          </div>
        </div>
      </div>

      <div className='relative mt-24'>
        <div className='lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24'>
          <div className='px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0'>
            <div>
              <div>
                <span className='h-12 w-12 rounded-md flex items-center justify-center bg-primary-600'>
                  <AdjustmentsIcon
                    className='h-6 w-6 text-white'
                    aria-hidden='true'
                  />
                </span>
              </div>
              <div className='mt-6'>
                <h2 className='text-3xl font-extrabold tracking-tight text-gray-100'>
                  Adjustments Every Step of the Way{' '}
                </h2>
                <p className='mt-4 text-lg text-gray-400'>
                  The JuggernautBJJ App keeps learning about your needs as an
                  athlete through our Daily Readiness Questionnaire,
                  Intra-Session Performance Ratings and End of Session
                  Check-Ins, this information helps our system keep adapting to
                  your needs:
                </p>
                <ul className='mt-6 space-y-4'>
                  {adjustments.map((adj, index) => (
                    <li key={index} className='flex space-x-3'>
                      <CheckIcon
                        className='flex-shrink-0 h-5 w-5 text-green-500'
                        aria-hidden='true'
                      />{' '}
                      <span className='text-gray-400'>{adj}</span>
                    </li>
                  ))}
                </ul>
                <div className='mt-6'>
                  <Link
                    href={{
                      pathname: '/register',
                      query: router.query.via ? { via: router.query.via } : {},
                    }}>
                    <a className='inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700'>
                      Get started
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className='mt-8 border-t border-gray-200 pt-6'>
              <blockquote>
                <div>
                  <p className='text-base text-gray-400'>
                    &ldquo;It’s a good combination of providing guidance for me
                    and it gives me flexibility to make changes if I need to.
                    I’ve used in-person trainers in the past and found this app
                    to be much more helpful and at a much lower price.&rdquo;
                  </p>
                </div>
                <footer className='mt-3'>
                  <div className='flex items-center space-x-3'>
                    <div className='text-base font-medium text-white'>
                      Nick B
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className='mt-12 sm:mt-16 lg:mt-0'>
            <div className='pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full'>
              <Image
                src='/slide3.png'
                alt='Juggernaut AI'
                width={1600}
                height={1829}
                layout='responsive'
                objectFit='contain'
              />
              {/* <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="https://tailwindui.com/img/component-images/inbox-app-screenshot-1.jpg"
                    alt="Inbox user interface"
                  /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
