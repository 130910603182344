import {
  AnnotationIcon,
  GlobeAltIcon,
  LightningBoltIcon,
  ScaleIcon,
  DuplicateIcon,
  CollectionIcon,
} from '@heroicons/react/outline'

const features = [
  {
    name: '5 Different Programs Tailored to Your Goals',
    description:
      'Focus on whatever is lacking in your game, Strength, Speed/Power or Endurance, peak for a tournament or focus on all-around development with our Lifestyle program',
    icon: DuplicateIcon,
  },
  {
    name: 'Community Support',
    description:
      'All JuggernautBJJ users get exclusive access to our private Facebook community where you can ask questions and get feedback from Coach Chad and fellow members.',
    icon: LightningBoltIcon,
  },
  {
    name: 'Backed by Experts',
    description:
      'Chad Wesley Smith has been training Jiu Jitsu athletes for over 12 years and is trusted by champions like Romulo Barral and Otavio Sousa',
    icon: AnnotationIcon,
  },
  {
    name: '400+ Exercises',
    description:
      'Never worry about how to do an exercise again. Our database has over 400 movements with video demonstrations and coaching cues to make sure you’re performing every movement properly',
    icon: CollectionIcon,
  },
]

const SecondFeatures = () => {
  return (
    <div className='py-24 bg-primary-900'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='lg:text-center'>
          {/* <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
          <p className='mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl'>
            And so much more...
          </p>
          <p className='mt-4 max-w-2xl text-xl text-gray-200 lg:mx-auto'>
            JuggernautBJJ is jam packed with features that will bring your
            training to new standards and boost your performance on the mats.
          </p>
        </div>

        <div className='mt-10'>
          <dl className='space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10'>
            {features.map((feature) => (
              <div key={feature.name} className='relative'>
                <dt>
                  <div className='absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-200 text-white'>
                    <feature.icon className='h-6 w-6' aria-hidden='true' />
                  </div>
                  <p className='ml-16 text-lg leading-6 font-medium text-white'>
                    {feature.name}
                  </p>
                </dt>
                <dd className='mt-2 ml-16 text-base text-gray-300'>
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

export default SecondFeatures
