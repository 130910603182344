import Link from 'next/link'
import { useRouter } from 'next/router'

export default function CTABanner() {
  const router = useRouter()
  return (
    <div className='bg-gray-900'>
      <div className='max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between'>
        <h2 className='text-3xl font-extrabold tracking-tight text-gray-100 sm:text-4xl'>
          <span className='block'>Ready to dive in?</span>
          <span className='block text-primary-600'>
            Start your free trial today.
          </span>
        </h2>
        <div className='mt-8 flex lg:mt-0 lg:flex-shrink-0'>
          <div className='inline-flex rounded-md shadow'>
            <Link
              href={{
                pathname: '/register',
                query: router.query.via ? { via: router.query.via } : {},
              }}>
              <a className='inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700'>
                Get started
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
