import { useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import Stats from '@/components/components/homepage/Stats'
import HowItWorks from '@/components/components/homepage/HowItWorks'
import Features from '@/components/components/homepage/Features'
import SecondFeatures from '@/components/components/homepage/SecondFeatures'
import CTABanner from '@/components/components/homepage/CTABanner'
import HomeFAQ from '@/components/components/homepage/FAQ'
import { useRouter } from 'next/router'

/* This example requires Tailwind CSS v2.0+ */
import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline'

const FooterHeader = () => {
  return (
    <>
      {/*
        Make sure you add some bottom padding to pages that include a sticky banner like this to prevent
        your content from being obscured when the user scrolls to the bottom of the page.
      */}
      <div className='fixed inset-x-0 bottom-0'>
        <div className='bg-primary-600'>
          <div className='max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8'>
            <div className='flex items-center justify-between flex-wrap'>
              <div className='w-0 flex-1 flex items-center'>
                <span className='flex p-2 rounded-lg bg-primary-800'>
                  <SpeakerphoneIcon
                    className='h-6 w-6 text-white'
                    aria-hidden='true'
                  />
                </span>
                <p className='ml-3 font-medium text-white truncate'>
                  <span className='md:hidden'>Looking For Powerlifting?</span>
                  <span className='hidden md:inline'>
                    Looking for Powerlifting / Powerbuilding? Head over to our
                    sister app JuggernautAI
                  </span>
                </p>
              </div>
              <div className='order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto'>
                <a
                  href='https://www.juggernautai.app'
                  className='flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-primary-600 bg-white hover:bg-primary-50'>
                  Learn more
                </a>
              </div>
              <div className='order-2 flex-shrink-0 sm:order-3 sm:ml-3'>
                <button
                  type='button'
                  className='-mr-1 flex p-2 rounded-md hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2'>
                  <span className='sr-only'>Dismiss</span>
                  <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const HeaderAnnouncement = ({ hideBanner }) => {
  return (
    <div className='fixed bottom-0 inset-x-0 pb-2 sm:pb-5'>
      <div className='max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>
        <div className='p-2 rounded-lg bg-primary-600 shadow-lg sm:p-3'>
          <div className='flex items-center justify-between flex-wrap'>
            <div className='w-0 flex-1 flex items-center'>
              <span className='flex p-2 rounded-lg bg-primary-800'>
                <SpeakerphoneIcon
                  className='h-6 w-6 text-white'
                  aria-hidden='true'
                />
              </span>
              <p className='ml-3 font-medium text-white truncate'>
                <span className='md:hidden'>Looking For Powerlifting?</span>
                <span className='hidden md:inline'>
                  Looking for Powerlifting / Powerbuilding? Head over to our
                  sister app JuggernautAI
                </span>
              </p>
            </div>
            <div className='order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto'>
              <a
                href='https://www.juggernautai.app'
                className='flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-primary-600 bg-white hover:bg-primary-50'>
                Learn more
              </a>
            </div>
            <div className='order-2 flex-shrink-0 sm:order-3 sm:ml-3'>
              <button
                type='button'
                className='-mr-1 flex p-2 rounded-md hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2'
                onClick={hideBanner}>
                <span className='sr-only'>Dismiss</span>
                <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Home = () => {
  const router = useRouter()
  const [showBanner, setShowBanner] = useState(true)
  return (
    <>
      {showBanner && (
        <HeaderAnnouncement hideBanner={() => setShowBanner(false)} />
      )}
      <div className='mx-auto lg:max-w-7xl lg:px-8 flex flex-row flex-wrap w-full h-screen'>
        <div className='w-full lg:w-1/2 relative h-1/2 lg:h-full text-white lg:flex lg:items-center'>
          <Image
            src='/BJJHome_mainSplash.png'
            objectFit='contain'
            width={650}
            height={650}
          />
        </div>
        <div className='w-full lg:mt-5 block lg:w-1/2 px-4 lg:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center'>
          <div>
            <h1 className=' text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-5xl xl:text-5xl'>
              <span className='block'>The Smartest Program</span>
              <span className='block text-primary-500'>For You</span>
            </h1>
            <p className='mt-3 text-base text-white sm:mt-5 sm:text-xl lg:text-lg xl:text-xl mb-5'>
              JuggernautBJJ is like having the best Strength & Conditioning
              coach in the world with you during every training session, helping
              you reach your true potential.
            </p>
            <Link
              href={{
                pathname: '/register',
                query: router.query.via ? { via: router.query.via } : {},
              }}>
              <button
                type='button'
                className='items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 w-1/2'>
                Get Started
              </button>
            </Link>
          </div>
        </div>
      </div>

      <Stats />
      <HowItWorks />
      <Features />
      <SecondFeatures />
      <CTABanner />
      <HomeFAQ />
    </>
  )
}

export default Home
